import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { IMAGE_CDN } from '../../config/constants';
import _ from 'lodash';
import {
  fetchHubsSpindleNut,
} from '../../actions';
import result from '../hub/details/result';


class HubResults extends React.Component {
  render() {
    let assemblyType = this.props.assemblyType;
    if (assemblyType) {
      return (
        <div className="details">
          <div>Best Value:</div>
          <ul>
            <li>Extended Warranty</li>
            <li>Less Downtime</li>
            <li>Simplified Process</li>
            {/*<li>Liability Protection</li>*/}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="details">
          <div>Requires:</div>
          <ul>
            <li>Bearing Cones</li>
            <li>Seal</li>
            <li>Manual Adjustment</li>
          </ul>
        </div>
      );
    }
  }
}
class SetLink extends React.Component {
  render() {
    const note = this.props.note;
    const hubnumber = this.props.number;
    
    let text = note.Text;
    if (note.Links) {
      let firstPart = text.substr(0, text.indexOf("{{"));
      let secondPart = text.substr(text.indexOf("}}") + 2, text.length);
      let number = parseInt(text.split("{{")[1]) == NaN? hubnumber: parseInt(text.split("{{")[1]);
  
      return (
         <div className="note">{text}</div>
      );
    } else {
      return <div className="note">{text}</div>;
    }
  }
}

class CastingResult extends React.Component {
  constructor(props) {
    super(props);
    this.prevData = this.prevData.bind(this);
    this.nextData = this.nextData.bind(this);

    this.state = {
      selectedIndex: 0,
      resultsLength: 0,
      results: [],
      newResults: [],
      spindleNut: '',
    };
  }

  nextData() {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex <= this.state.resultsLength) {
      this.setState({ selectedIndex: selectedIndex + 1 });
    }
  }

  addLinks(str, links, IBLink) {
    if (str === undefined || links === undefined || str === "—") {
      return null;
    }
    let matches = str.match(/{{(.*?)}}/g) || [];
    matches = matches.map(function (n, idx) {
      if (links.length > 0) {
        return [
          n,
          n
            .replace(
              "{{",
                '<a href="'+IBLink+'">'
            )
            .replace("}}", "</a>"),
        ];
      }
    });
    matches.forEach((item) => {
      str = str.replace(item[0], item[1]);
    });
    return (
      <p className="note-addLinks" dangerouslySetInnerHTML={{ __html: str }} />
    );
  }

  prevData() {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex > 0) {
      this.setState({ selectedIndex: selectedIndex - 1 });
    }
  }
  componentDidMount() {
    const {  dispatch , CastingResult} = this.props;
    this.setGridSameHeight();
    let partNumber = '';
    if (CastingResult && CastingResult.length) {
      CastingResult.map((item, id) => {
        partNumber +=
          id + 1 === CastingResult.length
            ? item.HubAssemblyNumber
            : item.HubAssemblyNumber + ',';
      });
    }
    if (partNumber) {
      dispatch(fetchHubsSpindleNut(partNumber));
    }
  }
  setGridSameHeight() {
    const grid = document.querySelectorAll('.equalizer .small-6');
    if (grid && grid.length > 1) {
      grid.forEach((item, gridIndex) => {
        if (item && item.children && item.children.length) {
          const list = item.children;
          for (let i = 0; i < list.length; i++) {
            if (
              grid[gridIndex + 1] &&
              grid[gridIndex + 1].children[i] &&
              list[i].clientHeight !==
                grid[gridIndex + 1].children[i].clientHeight
            ) {
              const currList = list[i];
              const nextList = grid[gridIndex + 1].children[i];
              const height = currList.clientHeight && nextList.clientHeight;

              if (height) {
                const updateElementList = document.querySelectorAll(
                  '.equalizer .small-6 .' + currList.className
                );

                for (let j = 0; j < updateElementList.length; j++) {
                  updateElementList[j].style.height = height + 'px';
                }
              }
            }
          }
        }
      });
    }
  }
  render() {
    const { CastingResult , results} = this.props;
    let aftermarketHubAssemblies = [];
    let nonAftermarketHubAssemblies = [];     
    for (var i = 0; i < CastingResult.length; i++) {
      if(CastingResult[i].IsAftermarket){
          aftermarketHubAssemblies.push(CastingResult[i]);
      }else{
          nonAftermarketHubAssemblies.push(CastingResult[i]);
      }
    }
    
   if (aftermarketHubAssemblies.length === 1  && nonAftermarketHubAssemblies.length === 0)  {         
        const data = aftermarketHubAssemblies[this.state.selectedIndex];
        let assemblyType = data.AftermarketDescription.toLowerCase().includes('plus'); 
        let spindleNut = this.getSpindleNut(aftermarketHubAssemblies, results);         
        let note = this.gwarNote(data);
        return this.ReturnSingleCastingView( data, assemblyType, note, spindleNut)             
   }
   if (nonAftermarketHubAssemblies.length === 1 && aftermarketHubAssemblies.length === 0) {         
      this.state.resultsLength = nonAftermarketHubAssemblies.length;
      const data = nonAftermarketHubAssemblies[this.state.selectedIndex];
      let assemblyType = data.AftermarketDescription.toLowerCase().includes('plus');   
      let spindleNut = this.getSpindleNut(nonAftermarketHubAssemblies, results);     
      let note = this.gwarNote(data);
      return this.ReturnSingleCastingView( data, assemblyType,note,spindleNut)       
    }
    else if (aftermarketHubAssemblies.length == 2 && nonAftermarketHubAssemblies.length === 0) {
      this.state.resultsLength = aftermarketHubAssemblies.length;
      let spindleNut = this.getSpindleNut(aftermarketHubAssemblies, results);
      const data = aftermarketHubAssemblies[this.state.selectedIndex];
      let note = this.gwarNote(data);     
      let isTractor = data.AftermarketDescription.toLowerCase().includes('trailer');  
     if(isTractor){
      return this.ReturnCastingTrailerView(aftermarketHubAssemblies, spindleNut);
     }
      let assemblyType = data.AftermarketDescription.toLowerCase().includes('plus');   
      return this.ReturnSingleCastingView( data, assemblyType,note,spindleNut) 
    }
    else if (nonAftermarketHubAssemblies.length == 2 && aftermarketHubAssemblies.length === 0 ) {
      //check if there a MTO and Terminated
      let activeHubAssemblies = [];
      let terminatedHubAssemblies = []; 
      for (var i = 0; i < nonAftermarketHubAssemblies.length; i++) {
        if(nonAftermarketHubAssemblies[i].PartStatus == 'Active' || nonAftermarketHubAssemblies[i].PartStatus == 'Service Only'){
          activeHubAssemblies.push(nonAftermarketHubAssemblies[i])
        }else if(nonAftermarketHubAssemblies[i].PartStatus == 'Terminated'){
          
            terminatedHubAssemblies.push(nonAftermarketHubAssemblies[i])
        }
      }
      if(activeHubAssemblies.length >= terminatedHubAssemblies.length){
        this.state.resultsLength = activeHubAssemblies.length;
        let spindleNut = this.getSpindleNut(activeHubAssemblies, results);      
        const data = activeHubAssemblies[this.state.selectedIndex];       
        let note = this.gwarNote(data);         
        let assemblyType = data.AftermarketDescription.toLowerCase().includes('plus');   
        //return this.ReturnCastingTrailerView( activeHubAssemblies, spindleNut);
        return this.ReturnSingleCastingView( data, assemblyType,note,spindleNut) 

      }else{
        this.state.resultsLength = terminatedHubAssemblies.length;
        let spindleNut = this.getSpindleNut(terminatedHubAssemblies, results);      
        const data = terminatedHubAssemblies[this.state.selectedIndex];       
        let note = this.gwarNote(data); 
        let isTractor = data.AftermarketDescription.toLowerCase().includes('trailer');  
        return this.ReturnCastingTrailerView( terminatedHubAssemblies, spindleNut);
      }
       
    }    
    else if (aftermarketHubAssemblies.length > 0) {
          if(aftermarketHubAssemblies.length == 1) {
            const data = aftermarketHubAssemblies[this.state.selectedIndex];
            let assemblyType = data.AftermarketDescription.toLowerCase().includes('plus'); 
            let spindleNut = this.getSpindleNut(aftermarketHubAssemblies, results);         
            let note = this.gwarNote(data);
            return this.ReturnSingleCastingView( data, assemblyType, note, spindleNut)  
          }
           if (aftermarketHubAssemblies.length == 2) {
            this.state.resultsLength = aftermarketHubAssemblies.length;
            let spindleNut = this.getSpindleNut(aftermarketHubAssemblies, results);      
            const data = aftermarketHubAssemblies[this.state.selectedIndex];       
            let note = this.gwarNote(data); 
            let isTractor = data.AftermarketDescription.toLowerCase().includes('trailer');  
      
              return this.ReturnCastingTrailerView( aftermarketHubAssemblies, spindleNut);       
          }

        this.state.resultsLength = aftermarketHubAssemblies.length;
        const data = aftermarketHubAssemblies[this.state.selectedIndex];
        let assemblyType = data.AftermarketDescription.toLowerCase().includes('preset');  
        let spindleNut = this.getSpindleNut(aftermarketHubAssemblies, results);          
        let note = this.gwarNote(data);
        return this.ReturnCastingViews(aftermarketHubAssemblies, data, assemblyType,note, spindleNut);
    }
    else if (nonAftermarketHubAssemblies.length > 0) {
      this.state.resultsLength = nonAftermarketHubAssemblies.length;    
      let activeHubAssemblies = [];
      let terminatedHubAssemblies = []; 
      for (var i = 0; i < nonAftermarketHubAssemblies.length; i++) {
        if(nonAftermarketHubAssemblies[i].PartStatus == 'Active' || nonAftermarketHubAssemblies[i].PartStatus == 'Service Only'){
          activeHubAssemblies.push(nonAftermarketHubAssemblies[i])
        }else if(nonAftermarketHubAssemblies[i].PartStatus == 'Terminated'){
          {
            terminatedHubAssemblies.push(nonAftermarketHubAssemblies[i])
        }
      }
    } 
    if(activeHubAssemblies.length <=2 && activeHubAssemblies.length !==0){
      this.state.resultsLength = activeHubAssemblies.length;
      let spindleNut = this.getSpindleNut(activeHubAssemblies, results);      
      const data = activeHubAssemblies[this.state.selectedIndex];       
      let note = this.gwarNote(data);         
      let assemblyType = data.AftermarketDescription.toLowerCase().includes('plus');   
      //return this.ReturnCastingTrailerView( activeHubAssemblies, spindleNut);
      return this.ReturnCastingTrailerView( activeHubAssemblies,assemblyType,note,spindleNut) 

    }else{
      return this.ReturnCastingViews(activeHubAssemblies, terminatedHubAssemblies);
    }
  }

    else {
      return <div />;
      }
    }

  gwarNote(data) {
    let note = null;
    if (!_.isUndefined(data.GawrNote)) {
      note = this.addLinks(data.GawrNote.Text, data.GawrNote.Links, data.GawrNote.IBLink);
    }
    return note;
  }

   ReturnCastingViews(activeHubAssemblies,terminatedHubAssemblies) {
    return (
      <div className="grid-container main-content" id="hubAssemblyResult">
           <h2>The following Hubs meet your parameters</h2>
               
              {(activeHubAssemblies.length  > 0) ?(
               activeHubAssemblies.map((c, index) => {                                 
                  return (            
                      <Link
                      to={'/hub/details/' + c.HubAssemblyNumber}
                      key={index}
                      className="general-button"
                    >
                      {c.HubAssemblyNumber} - {c.AftermarketDescription}
                    </Link>
                  );
                               
              })) :(
                ""
              )}
             {(terminatedHubAssemblies.length > 0 && activeHubAssemblies.length == 0 ) ?(
               terminatedHubAssemblies.map((c, index) => {                                 
                  return (            
                      <Link
                      to={'/hub/details/' + c.HubAssemblyNumber}
                      key={index}
                      className="general-button"
                    >
                      {c.HubAssemblyNumber} - {c.AftermarketDescription}
                    </Link>
                  );                                 
              })) :(
                ""
              )}                               
        </div>
      
      )
   }

  getSpindleNut(aftermarketHubAssemblies, results) {
    let spindleNut = '';
    let partNumber = '';
    if (aftermarketHubAssemblies && aftermarketHubAssemblies.length) {
      aftermarketHubAssemblies.map((item, id) => {
        partNumber +=
          id + 1 === aftermarketHubAssemblies.length
            ? item.HubAssemblyNumber
            : item.HubAssemblyNumber + ',';
      });
    }
    if (results.spindleNut !== "" && results.spindleNut !== undefined) {
      results.spindleNut[0].AftermarketParts.map(
        (item, id) => {
          let washer_description = '';
          if (item.Description.toLowerCase().includes('hub service kit') &&
            item.Description.toLowerCase().includes('ff')) {
            if (item.TypeId === 226) {
              washer_description = ' (D Flat)';
            } else if (item.TypeId === 227) {
              washer_description = ' (Keyway)';
            }
          }
          spindleNut +=
            id + 1 ===
              results.spindleNut[0].AftermarketParts.length
              ? item.PartNumber + washer_description
              : item.PartNumber + washer_description + ',';
        }
      );

    }
    return spindleNut;
  }
  
  ReturnSingleCastingView(data, assemblyType, note, spindleNut) {
    return (
      <div >
      <h2>Success! The following hub is recommended</h2>
            <div className="result">
                {data.Images.map((item, index) => {
                  // return <img className="product-image"  src={IMAGE_CDN+image.ImageGuid+'.png'}  key={index} alt={data.BrakeDrumNumber} width="200" height="200" />
                  return (
                    <img
                          key={index}
                          className="product-image"    
                          src={IMAGE_CDN + item.ImageGuid + '.png'}            
                          //src={item.ImageUrl + ".png"}
                          alt={item.HubAssemblyNumber}
                          width="250"
                          height="250"
                        />
                    );
                })}
                {assemblyType === true ? (
                  <h2 className="hub-name">

                    <br />
                    {data.title || data.AftermarketDescription}
                    <br />
                    {data.HubAssemblyNumber}
                    <br />
                  </h2>
                ) : (
                    ''
                  )}
                {assemblyType === false ? (
                  <h2 className="hub-name">
                    {data.title || data.AftermarketDescription}
                    <br />
                    {data.HubAssemblyNumber}
                    <br />              
                  </h2>
                ) : (
                    ''
                  )}
                {data.PartStatus !== "Terminated" && spindleNut && (
              <div className="optional-spindle">
                5 years/500,000 mile warranty when installed with PreSet Plus
                Spindle Nut: {spindleNut} (PreSet Hubs Only)
              </div>
            )}
            {data.PartStatus !== "Terminated" && spindleNut && (
              <div className="warranty-spindle">
                {" "}
                *For full warranty details click{" "}
                <a href={data.WarrantyLink}>
                  {" "}
                  here.
                </a>
              </div>
            )}
                 {(data.IsAftermarket === false && data.PartStatus === 'Active') ||
                  data.PartStatus === 'Service Only' ? (
                    <div className="optional-spindle">
                      This hub is made-to-order only. Please contact ConMet Customer
                            Service for availability
                    </div>
                  ) : (
                    ''
                  )}
                {data.PartStatus === 'Terminated' ? (
                  <div className="optional-spindle">
                              This hub is no longer available. Please contact ConMet Customer
                            Service for options
                  </div>
                ) : (
                    ''
                  )}
                  <div className="optional-spindle">
                  {note}                
                  </div>
                <Link to={'/hub/details/' + data.HubAssemblyNumber} className="general-button">
                See Details
                </Link>
              </div>
            </div>           
    );
  }
  ReturnCastingTrailerView( data,spindleNut) {
    return (
      <div className="grid-container main-content" id="hubAssemblyResult">
      <h2>Success!</h2>
      <div className="grid-content flex-row">
        {data.map((item, index) => {
      
        let assemblyType = item.AftermarketDescription.toLowerCase().includes("preset");
        return (
          <div className={data.length === 2 ? "small-6" : "small-12"} key={index}>
              <img
                  className="product-image"
                  src={require("../../images/blank1x1.png")}
                  width="250"
                  height="250"
                  src={IMAGE_CDN + item.Images[0].ImageGuid + '.png'}   
                  alt={item.HubAssemblyNumber}
                />
                  <div className="type">
                  {assemblyType ? "PreSet" : "Conventional"}
                </div>
                <div className="number italic">
               
                 {item.HubAssemblyNumber}
                 <HubResults assemblyType={assemblyType} key={index} />
                 </div>
                 {(item.IsAftermarket === false && item.PartStatus === 'Active') ||
                  item.PartStatus === 'Service Only' ? (
                    <div className="optional-spindle">
                      This hub is made-to-order only. Please contact ConMet Customer
                            Service for availability
                    </div>
                  ) : (
                    ''
                  )}
               {item.PartStatus === 'Terminated' ? (
                  <div className="optional-spindle">
                              This hub is no longer available. Please contact ConMet Customer
                            Service for options
                  </div>
                ) : (
                    ''
                  )}
                
            </div>
            )
        })}
        {spindleNut && (
            <div className="optional-spindle" style={{ textAlign: "center" }}>
              5 years/500,000 mile warranty when installed with PreSet Plus
              Spindle Nut: {spindleNut} ( PreSet Hubs Only)
            </div>
          )}
        {spindleNut && (
            <div className="warranty-spindle">
              {" "}
              *For full warranty details click{" "}
              <a href={data[0].WarrantyLink ? data[0].WarrantyLink : "https://cdn.intelligencebank.com/us/share/ONnl/b4Xk/qqy6/original/Limited+Warranty+-+Aftermarket+101-1081-G005+%2810045532%29+Rev+F"}>
                {" "}
                here.
              </a>
            </div>
          )}
           {data.map((item, index) => {
            return (
              <div
                className={data.length === 2 ? "small-6" : "small-12"}
                key={index}
              >
                <Link
                  to={"/hub/details/" + item.HubAssemblyNumber}
                  key={index}
                  className="general-button"
                >
                  See Details
                </Link>
              </div>
            );
          })}
        {data[0].GawrNote === "" ? (
         <SetLink note={data[0].GawrNote} />
         ) : ""}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(CastingResult);