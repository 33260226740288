import React, { Component, Image } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showPreviousResult, showNextResult } from '../../../actions';
import _ from 'lodash';
import Spinner from '../../../components/global/spinner';
import { fetchLongStudApi } from '../../../utils';
import { appInsights } from '../../../AppInsights';

class NextButton extends Component {
  nextClass() {
    let { idx, total, showButton } = this.props;
    let defaultClass = "next-button";
    defaultClass = showButton() ? defaultClass + " hide-button" : defaultClass;
    if (idx === total - 1) {
      return defaultClass + " disabled";
    }
    return defaultClass;
  }

  render() {
    let { handleClick } = this.props;

    return (
      <div className={this.nextClass()} onClick={handleClick}>
        <i className="icon-angle-right" />
      </div>
    );
  }
}

class PreviousButton extends Component {
  previousClass() {
    let { idx, showButton } = this.props;
    let defaultClass = "prev-button";
    defaultClass = showButton() ? defaultClass + " hide-button" : defaultClass;
    if (idx === 0) {
      return defaultClass + " disabled";
    }
    return defaultClass;
  }

  render() {
    let { handleClick } = this.props;
    return (
      <div className={this.previousClass()} onClick={handleClick}>
        <i className="icon-angle-left" />
      </div>
    );
  }
}

class HubResults extends React.Component {
  render() {
    let assemblyType = this.props.assemblyType;
    if (assemblyType) {
      return (
        <div className="details">
          <div>Best Value:</div>
          <ul>
            <li>Extended Warranty</li>
            <li>Less Downtime</li>
            <li>Simplified Process</li>
            {/*<li>Liability Protection</li>*/}
          </ul>
        </div>
      );
    }
  }
}

class HubSingleResult extends Component {
  constructor() {
    super();

    this.compareLongStuds = this.compareLongStuds.bind(this);

    this.state = {
      isFetching: false,
      shortStuds: [],
    };
  }

  renderButtons() {
    let { total } = this.props;
    return total === 1;
  }

  handleNextClick() {
    const { dispatch } = this.props;
    dispatch(showNextResult());
  }

  handlePreviousClick() {
    const { dispatch } = this.props;
    dispatch(showPreviousResult());
  }

  renderPreviousBtn() {
    if (this.renderButtons()) {
    }
    return "";
  }

  renderNextBtn() {
    if (this.renderButtons()) {
    }
    return "";
  }

  addLinks(str, links, IBLink) {
    if (str === undefined || links === undefined || str === "—") {
      return null;
    }
    let matches = str.match(/{{(.*?)}}/g) || [];

    matches = matches.map(function (n, idx) {
      if (links.length > 0) {
        return [
          n,
          n
            .replace(
              "{{",
              '<a href="'+IBLink+'">'
            )
            .replace("}}", "</a>"),
        ];
      }
    });
    matches.forEach((item) => {
      str = str.replace(item[0], item[1]);
    });
    return (
      <p className="note-addLinks" dangerouslySetInnerHTML={{ __html: str }} />
    );
  }

  componentWillMount() {
    const { short_studs } = this.props;
    console.log(short_studs);
    if (short_studs && short_studs.length > 1) {
      this.setState({ isFetching: true });
      this.compareLongStuds();
    }
  }

  async compareLongStuds() {
    const { item, short_studs } = this.props;
    const longStudFilter = `hanum=${item.HubAssemblyNumber}`;
    const t = await fetchLongStudApi(longStudFilter);
    const shortStuds = [];
    const promises = short_studs.map(async (item) => {
      const res = await fetchLongStudApi(
        `${longStudFilter},${item.HubAssemblyNumber}`
      );
      if (_.isEqual(_.sortBy(t.Results), _.sortBy(res.Results))) {
        shortStuds.push(item.HubAssemblyNumber);
      }
    });

    await Promise.all(promises);
    this.setState({ isFetching: false, shortStuds });
  }

  render() {
    let {
      idx,
      total,
      item,
      spindleNut,
      short_studs,
      selectedHubAssemblyNumber,
      results
    } = this.props;
    const selectedNumber = selectedHubAssemblyNumber
      ? selectedHubAssemblyNumber.split(" ")[1]
      : "";
    const selectedConmet = selectedHubAssemblyNumber
      ? selectedHubAssemblyNumber.split(" ")[0]
      : "";
    const { isFetching, shortStuds } = this.state;
    let studs = null;
    let assemblyType = item.AftermarketDescription.toLowerCase().includes("plus") || item.AftermarketDescription.toLowerCase().includes("premium");
    let hubAssemblynumber =item.HubAssemblyNumber
    appInsights.trackEvent({ name: 'Hub Assembly Number:', properties: {hubAssemblynumber} })
    
    if (_.isUndefined(item) || item.id === -1 || isFetching) {
      return <Spinner isFetching />;
    }

    let note = null;

    if (!_.isUndefined(item.GawrNote)) {
      note = this.addLinks(item.GawrNote.Text, item.GawrNote.Links, item.GawrNote.IBLink);
    }

    if (short_studs && short_studs.length === 1) {
      studs = short_studs.map((item) => item.HubAssemblyNumber).join(",");
    } else if (shortStuds && shortStuds.length) {
      studs = shortStuds.map((item) => item).join(",");
    }
    let isPublic = results && results[0] ? results[0].AftermarketParts[0].isPublic : null;

    return (
      <div>
        {/*<h2>Success! The following hub is recommended</h2>*/}
        <h2>
          {item.PartStatus === "Active" || item.PartStatus === "Service Only"
            ? "The following hub is recommended"
            : "This hub is no longer available"}
        </h2>
        {selectedHubAssemblyNumber ? (
          <p
            className="text-center"
            style={selectedConmet === "ConMet" ? { marginBottom: 0 } : null}
          >
            for {selectedHubAssemblyNumber}
          </p>
        ) : (
          ""
        )}
        {/*{selectedConmet === 'ConMet' && item.PartStatus === 'Active' ? <div className="serviceComponent"><Link to={'/parts/search/' +  selectedNumber} className="general-button">Looking for service components for {selectedNumber}?</Link></div> : ''}*/}
        <div className="result">
          <PreviousButton
            idx={idx}
            total={total}
            handleClick={this.handlePreviousClick.bind(this)}
            showButton={this.renderButtons.bind(this)}
          />

          <div className="details">
            {item.Images.map((image, index) => {
              if (index === 0) {
                return (
                  <img
                    key={index}
                    className="product-image"
                    // src={IMAGE_CDN + image.ImageGuid + '.png'}
                    src={image.ImageUrl + ".png"}
                    alt={item.HubAssemblyNumber}
                    width="200"
                    height="200"
                  />
                );
              }
            })}

            <h2 className="hub-name">
              {item.title || item.AftermarketDescription}
              <br />
              {item.HubAssemblyNumber}
              <br />
              {studs && `${studs} (Long stud version)`}
            </h2>

            {assemblyType === true ? (
                <h2 className="hub-name">
                  <div className='bold'>Best Value:</div>
                  <ul>
                    <li>5 years/500,000 mile warranty</li>
                    <li>Less Downtime</li>
                    <li>Simplified Process</li>
                  </ul>
                </h2>
            ) : (
              ""
            )}

           {spindleNut && isPublic && (
            <div className="optional-spindle" style={{ textAlign: "center" }}>
              5 years/500,000 mile warranty when installed with PreSet Plus
              Spindle Nut: {spindleNut} ( PreSet Hubs Only)
            </div>
          )}

            {item.PartStatus !== "Terminated" && spindleNut && (
              
              <div className="warranty-spindle">
                {" "}
                *For full warranty details click{" "}
                <a href={item.WarrantyLink}>
                  {" "}
                  here.
                </a>
              </div>
            )}

            {(item.IsAftermarket === false && item.PartStatus === "Active") ||
            item.PartStatus === "Service Only" ? (
              <div className="optional-spindle">
                This hub is made-to-order only. Please contact ConMet Customer
                Service for availability
              </div>
            ) : (
              ""
            )}
            {item.PartStatus === "Terminated" ? (
              <div className="optional-spindle">
                This hub is no longer available. Please contact ConMet Customer
                Service for options
              </div>
            ) : (
              ""
            )}
            {note}
            {item.Images.map((image, index) => {
              if (index === 1) {
                return (
                  <img
                    key={index}
                    className="product-image danger"
                    // src={IMAGE_CDN + image.ImageGuid + '.png'}
                    src={image.ImageUrl + ".png"}
                    alt={item.HubAssemblyNumber}
                    width="200"
                    height="200"
                  />
                );
              }
            })}
            <Link
              to={"/hub/details/" + item.HubAssemblyNumber}
              className="general-button"
            >
              See Details
            </Link>
         </div>
          <NextButton
            idx={idx}
            total={total}
            handleClick={this.handleNextClick.bind(this)}
            showButton={this.renderButtons.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default connect()(HubSingleResult);
